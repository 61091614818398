<!-- Card Body -->
<card-body class=" p-5">
  <div class="d-flex mb-5">

    <span *ngIf="data.heading"
        class="d-block font-weight-500 f-16"
        [ngClass]="data.heading.color">{{data.heading.value}}</span>

    <div *ngIf="data.trending.value && data.trending.icon"
         class="d-flex align-items-center ml-auto" [ngClass]="data.trending.color">

      <span *ngIf="data.trending.value" class="mb-0 font-weight-500 mr-1">{{data.trending.value}}</span>

      <gx-icon *ngIf="data.trending.icon"
               [name]="data.trending.icon"></gx-icon>
    </div>
  </div>
  <!-- Grid -->

  <div class="row no-gutters">

    <!-- Grid Item -->
    <div class="col-xl-{{coloums[0]}} col-sm-{{coloums[0]}} col-md-12 pr-2">
      <span class="text-dark display-3 font-weight-500 mr-1">{{data.amount}}</span>
      <p *ngIf="data.tagLine" class="mb-0" [ngClass]="data.tagLine.color">{{data.tagLine.text}}</p>
    </div>
    <!-- /grid item -->

    <!-- Grid Item -->
    <div class="col-xl-{{coloums[1]}} col-sm-{{coloums[1]}} col-md-12">

      <!-- Chart -->
      <ng-content *ngIf="chart" select="gx-chart"></ng-content>
      <!-- /chart -->

    </div>
    <!-- /grid item -->

  </div>
  <!-- /grid -->
</card-body>
<!-- /card body -->
