<card-body class="p-xl-8 py-sm-8 py-6 px-4">
  <span *ngIf="badge" class="badge badge-top-right" [ngClass]="badge.color">{{badge.name}}</span>

  <!-- Media -->
  <div class="media">

    <gx-icon *ngIf="icon"
             size="5x"
             class="mr-xl-5 mr-3 align-self-center"
             [name]="icon.name"
             [ngClass]="icon.color"></gx-icon>

    <!-- Media Body -->
    <div *ngIf="items.length > 0" class="media-body">
      <ng-container *ngIf="items.length == 1">
        <p class="mb-1 h1">{{items[0].value}}</p>
        <span class="d-block text-light-gray">{{items[0].text}}</span>
      </ng-container>

      <ul *ngIf="items.length > 1" class="invoice-list">
        <li class="invoice-list__item" *ngFor="let item of items">
          <span class="invoice-list__number">{{item.value}}</span>
          <span *ngIf="item.tooltip" class="dot-shape" [ngClass]="item.tooltip.color"></span>
          <span class="invoice-list__label">{{item.text}}</span>
          <span *ngIf="item.tooltip" class="custom-tooltip" [ngClass]="item.tooltip.color">{{item.tooltip.value}}</span>
        </li>
      </ul>

    </div>
    <!-- /media body -->

  </div>
  <!-- /media -->
</card-body>
