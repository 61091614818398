<!-- Card Body -->
<div class="card-body py-3 px-1">
  <!-- Icon -->
  <gx-icon [name]="icon" [size]="iconSize" [ngClass]="iconClasses"></gx-icon>
  <!-- /icon -->
  <span class="d-block f-12">{{label | titlecase}}</span>
</div>
<!-- /card body -->


