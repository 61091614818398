<card-body>

  <div class="mb-5">
    <div class="d-flex mb-1">
      <div class="display-1 text-dark mr-3 font-weight-light">4.5</div>
      <div class="rating-container flex-column">
        <div class="ratings">
          <span class="rated" [ngStyle]="{width:'88%'}"></span>
        </div>

        <div class="ratings-review f-16 text-light-gray">
          <span class="count-number text-light-gray">350</span> Reviews
        </div>
      </div>
    </div>
    <h4 class="mb-0">Overall Ratings</h4>
  </div>

  <!-- List -->
  <ul class="dt-list flex-column">
    <!-- List Item -->
    <li class="dt-list__item" *ngFor="let review of reviews">
      <div class="rating-container">
        <div class="ratings">
          <span class="rated" [ngStyle]="{width:getPercentageWidth(review)}"></span>
        </div>

        <div class="ratings-review">
          <span class="count-number">{{review.totalReview}}</span> Reviews
        </div>
      </div>
    </li>
    <!-- /list item -->
  </ul>
  <!-- /list -->

</card-body>
