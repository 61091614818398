import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gx-value-calculator',
  templateUrl: './gx-value-calculator.component.html',
  styleUrls: ['./gx-value-calculator.component.scss']
})
export class GxValueCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
