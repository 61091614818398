import {ApplicationRef, Component, HostBinding} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Location} from '@angular/common';
import { BaseServiceService } from '@app/services/base-service.service';
import firebase from 'firebase';
// import { NewsletterServiceService } from '@app/services/newsletter-service.service';
import {Subscription} from 'rxjs';
import {NgProgress} from 'ngx-progressbar';
import axios from 'axios';
// import { SwUpdate, SwPush } from '@angular/service-worker';
import { interval } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '@app/layouts/auth-layout/auth.service';
import {SettingsService} from '@app/settings/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  readonly VAPID_PUBLIC_KEY = "BAVGDCXrfGFSKqrHW_DSnlOOFPEmGh1zrlRtMBA5jaivUpwuYju2dp5vNe5VXrZjetBOXSfr9Vt-qGW6rZPS5OE";

  @HostBinding('class') classlist = 'dt-root';

  route: string;
  isAuthenticated: any = false;
  settings: any;
  id:any;
  apiData:any;
  onSettingChanged: Subscription;

  constructor(public translate: TranslateService,
              public authService: AuthService,
              public settingService: SettingsService,
              // private swPush: SwPush,
              public baseService : BaseServiceService,
              private appRef: ApplicationRef,
              // private update: SwUpdate,
              // private newsletterService: NewsletterServiceService,
              public ngProgress: NgProgress,
              private router: Router,
              location: Location) {
                if(!!JSON.parse( window.localStorage.getItem("userData")))
                this.id = JSON.parse( window.localStorage.getItem("userData"))._id;
                // this.updateClient();
                // this.checkUpdate();
                // this.subscribeToNotifications();
    // set translation
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    const _language =  window.localStorage.getItem('language');
    if (_language) {
      translate.use(_language);
    }
    // set translation end

    this.onSettingChanged = this.settingService.onSettingChanged.subscribe(
      (newSettings) => {
        this.settings = newSettings;
      }
    );

    this.isAuthenticated = this.authService.isAuthenticated();

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.ngProgress.start();
        this.isAuthenticated = this.authService.isAuthenticated();
      }

      if (event instanceof NavigationEnd) {
        this.isAuthenticated = this.authService.isAuthenticated();
        this.ngProgress.done();

        const {fragment} = router.parseUrl(router.url);
        if (fragment) {
          const element = document.querySelector(`#${fragment}`);
          if (element) {
            element.scrollIntoView();
          }
        } else {
          // window.scrollTo({top: 0});
        }
      }

      this.route = location.path();
    });


  }

  //////////Test
  // updateClient() {
  //   if (!this.update.isEnabled) {
  //     console.log('Not Enabled');
  //     return;
  //   }
  //   this.update.available.subscribe((event) => {
  //     console.log(`current`, event.current, `available `, event.available);
  //     if (confirm('update available for the app please conform')) {
  //       this.update.activateUpdate().then(() => location.reload());
  //     }
  //   });

  //   this.update.activated.subscribe((event) => {
  //     console.log(`current`, event.previous, `available `, event.current);
  //   });
  // }

  // checkUpdate() {
  //   this.appRef.isStable.subscribe((isStable) => {
  //     if (isStable) {
  //       const timeInterval = interval(8 * 60 * 60 * 1000);

  //       timeInterval.subscribe(() => {
  //         this.update.checkForUpdate().then(() => console.log('checked'));
  //         console.log('update checked');
  //       });
  //     }
  //   });
  // }

  // pushSubscription() {
  //   if (!this.swPush.isEnabled) {
  //     console.log('Notification is not enabled');
  //     return;
  //   }

  //   this.swPush
  //     .requestSubscription({
  //       serverPublicKey: this.VAPID_PUBLIC_KEY,
  //     })
  //     .then((sub) => {
  //       // Make a post call to serve
  //       console.log(JSON.stringify(sub));
  //       var data:any ={};
  //       data.notificattionToken = JSON.stringify(sub);
        
  //       axios.put(this.baseService.baseUrl + 'secure/users/updateNotificationToken/'+this.id,data,{
  //         headers: {
  //           'x-auth' : this.baseService.authToken
  //         },
          
  //       })
  //         .then(response => {        
  //           console.log(response)
  //           if (response.data.status) {
  //             console.log(response.data)
  //           // .then(() => {
  //           //   location.reload(); });
  //           }
  //           // else if(this.baseService.checkToken(response.data.message , response.data.status))
  //           // {
              
  //           // }
  //           else{
  //             console.log(response.data)
  //            }
  //         })
  //         .catch(function (error) {
  //           console.log(error)
  //         })        
  //     })
  //     .catch((err) => console.log(err));
  // }

  // postSync() {
  //   let obj = {
  //     name: 'Subrat',
  //   };
  //   //api call
  //    axios.post('http://localhost:8033/data', obj).then(
  //     (res) => {
  //       console.log(res);
  //     },
  //     (err) => {
  //       // this.indexedDBService
  //       //   .addUser(obj.name)
  //       //   .then(this.backgroundSync)
  //       //   .catch(console.log);
  //       this.backgroundSync();
  //     }
  //   );
  // }

  // backgroundSync() {
  //   navigator.serviceWorker.ready
  //     .then((swRegistration) => swRegistration.sync.register('post-data'))
  //     .catch(console.log);
  // }

  ngOnInit() {
    // this.pushSubscription();
    // console.log("pushSubscription")
    // this.swPush.messages.subscribe((message) => console.log(message));
    // this.swPush.notificationClicks.subscribe(({ action, notification }) => {
    //   console.log("notification Test")
    //   window.open(notification.data.url);
    // });

    //  axios.get('http://dummy.restapiexample.com/api/v1/employees').then(
    //   (res: any) => {
    //     this.apiData = res.data;
    //   },
    //   (err) => {
    //     console.error(err);
    //   }
    // );
  }

//   subscribeToNotifications() {

//     this.swPush.requestSubscription({
//         serverPublicKey: this.VAPID_PUBLIC_KEY
//     })
//     .then(sub => this.newsletterService.addPushSubscriber(sub).subscribe())
//     .catch(err => console.error("Could not subscribe to notifications", err));
// }

}
