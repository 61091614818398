import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import{ Router } from "@angular/router"
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class BaseServiceService {

  public baseUrl: String
  authToken: any;
  
  
  constructor (private httpService: HttpClient, private router:Router) {
    this.authToken =  window.localStorage.getItem("happiTicketToken");
    if (window.location.hostname === 'localhost') {
      this.baseUrl = environment.apiUrl
    } else {
      this.baseUrl = environment.apiUrl
    }
    // this.getTenantJson();
  } 

  checkToken(msg , status)
  {
    if(msg == "Invalid Token" && status == false)
    {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Invalid Token",
          showConfirmButton: false,
          timer: 2000
      });
       window.localStorage.clear();
      this.router.navigate(['/signin']);
      return false;
    }
    else
    {
     return true; 
    }
  }

  update()
  {
    this.authToken =  window.localStorage.getItem("happiTicketToken");
  }
  
}
