<!-- Social Circle -->
<div *ngIf="logoIcon" class="dt-social__circle" [ngClass]="circleColor">
  <gx-icon [name]="logoIcon" [fw]="true" size="3x" [ngClass]="iconColor"></gx-icon>
</div>
<!-- /social circle -->

<!-- Card Body -->
<card-body>
  <h3 class="font-weight-500 mb-1">{{title}}</h3>
  <!-- List -->
  <ul class="dt-list dt-list-bordered dt-list-cm-0 flex-nowrap">
    <!-- List Item -->
    <li class="dt-list__item text-truncate" *ngIf="likes">
      <span><span class="text-dark">{{likes}} </span>Likes</span>
    </li>
    <!-- /list item -->

    <!-- List Item -->
    <li class="dt-list__item text-truncate" *ngIf="friends">
      <span><span class="text-dark">{{friends}} </span>Friends</span>
    </li>
    <!-- /list item -->

    <!-- List Item -->
    <li class="dt-list__item text-truncate" *ngIf="followers">
      <span><span class="text-dark">{{followers}} </span>Followers</span>
    </li>
    <!-- /list item -->
  </ul>
  <!-- /list -->
</card-body>
<!-- /card body -->
