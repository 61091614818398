<!-- Overlay Content -->
<div class="bg-overlay__inner mt-auto">

  <!-- Card Body -->
  <div class="dt-card__body text-center">

    <h3 class="text-white mb-2">Refer & Earn $10</h3>

    <p>Reffer us to your friends and earn bonus when they join.</p>

    <input type="email" class="form-control form-control-transparent text-center mb-2" placeholder="Enter Email Address">
    <a class="btn btn-light btn-block" href="javascript:void(0)">Invite Friends</a>

  </div>
  <!-- /card body -->

</div>
<!-- /overlay content -->
