import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/layouts/auth-layout/auth.guard';
import { BackOfficeMiniLayoutComponent } from './layouts/back-office-mini-layout/back-office-mini-layout.component';
import { BackOfficeMiniLayoutModule } from './layouts/back-office-mini-layout/back-office-mini-layout.module';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/signin'
  },
  {
    path: '',
    loadChildren: () => import('./layouts/auth-layout/auth.module').then(m => m.AuthModule)
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./layouts/saas-layout/saas-layout.module').then(m => m.SaasLayoutModule),
  //   // canActivate: [AuthGuard]
  // },
  {
    path: '',
    loadChildren: () => import('./layouts/back-office-mini-layout/back-office-mini-layout.module').then(m => m.BackOfficeMiniLayoutModule),
    // canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/ticket/ticket-generate'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
