<div class="search-box">
  <form class="searc-form">
    <div class="form-container">
      <div class="form-field">
        <input type="search" class="form-element" placeholder="Search">
        <!--<mat-icon>search</mat-icon>-->
      </div>
    </div>
  </form>
</div>
