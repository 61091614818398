<!-- Card Header -->
<card-header class="px-5" [ngClass]="{'mb-4':style === 'basic' || style === 'classic'}">

  <!-- Card Heading -->
  <card-heading class="text-center">
    <div class="dt-separator-h-v1 mb-2" [ngClass]="data.handleColor"></div>
    <h3 class="{{data.titleStyle}}"
        [ngClass]="{'mb-1':style === 'basic', 'mb-0':style === 'panel' || style === 'classic'}">{{data.title}}</h3>
    <span *ngIf="data.content && style == 'basic'" class="d-inline-block text-primary">{{data.content}}</span>
  </card-heading>
  <!-- /card heading -->

</card-header>
<!-- /card header -->

<card-body [ngClass]="bodyClasses">

  <p class="mb-5" *ngIf="data.content && style == 'classic'">{{data.content}}</p>

  <!-- Avatar -->
  <img *ngIf="data.image && style === 'basic'"
       class="img-fluid mb-7"
       [src]="data.image"
       alt="{{data.title}}">

  <div class="mx-n8 mb-n8" *ngIf="data.image && style === 'panel'">
    <img class="w-100" [src]="data.image" alt="{{data.title}}">
  </div>
  <!-- /avatar -->

  <!-- Button -->

  <div>
    <a *ngIf="data.actionLabel && style === 'basic'"
       class="btn text-uppercase btn-sm"
       [ngClass]="data.actionStyles"
       [routerLink]="data.actionRoute">{{data.actionLabel}}</a>
  </div>

  <a *ngIf="data.actionLabel && style === 'classic'"
     class="text-uppercase f-12 font-weight-500"
     [ngClass]="data.actionStyles"
     [routerLink]="data.actionRoute">{{data.actionLabel}}</a>

  <!-- /button -->

</card-body>

<!-- Card Image -->
<img *ngIf="data.image && style === 'classic'" class="img-fluid mb-n15" [src]="data.image" alt="{{data.title}}">
<!-- /card Image -->

<!-- Button -->
<a *ngIf="data.actionLabel && style === 'panel'"
   class="btn btn-block rounded-0 text-uppercase"
   [ngClass]="data.actionStyles"
   [routerLink]="data.actionRoute">{{data.actionLabel}}</a>
<!-- /button -->
