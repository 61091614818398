import { InMemoryDbService } from 'angular-in-memory-web-api';

import {
  MailsDb
} from './data/apps/mail/mails-db';
import { MailLabelsDb } from './data/apps/mail/labels-db';
import {
  MailFiltersDb
} from './data/apps/mail/filters-db';
import { MailFoldersDb } from './data/apps/mail/folders-db';

import { MailContactsDb } from './data/apps/mail/contacts-db';

export class DbdataService implements InMemoryDbService {
  createDb() {
    return {

      //  // Mail
      'mail-list': MailsDb.list,
      'mail-folders': MailFoldersDb.list,
      'mail-filters': MailFiltersDb.list,
      'mail-labels': MailLabelsDb.list,
      'mail-contacts': MailContactsDb.list,
    }

  }
}
