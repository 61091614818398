<!-- User Information -->
<span class="user-bg-card__info">
  <span class="dt-avatar-name mb-1">{{client.name}}</span>
  <span class="d-flex align-items-center f-12">
    <span class="d-inline-block mr-2 pr-2 border-right">
      <gx-icon name="star-fill" class="mr-2 text-yellow"></gx-icon>
      <span>{{client.rating}}</span>
    </span>

    <span class="d-inline-block">{{client.deals}}</span>
  </span>
</span>
<!-- /user information -->
